import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    // Testing active-ants
    '71e6a45f-820f-42c6-913f-1a49c7e3692e': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Testing picqer
    'ae094d50-8313-43fa-bb62-24a3c1fee247': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Acceptance active-ants
    '3f1ad51d-cb94-4121-9749-e6b73a633b38': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Acceptance picqer
    '28726a03-cd25-4462-a66a-bb61eaacab0a': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Production active-ants
    'fba8a756-edbe-4057-b4f5-c110b1e0d70c': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Production picqer
    'dfb27379-ce0b-4339-9c4f-fe6193660fa4': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '5',
  },
  nextDayDeliveryTimeLimit: '17:30',
  productTypeDeliveryConfig: [
    {
      type: 'MIXED',
      expectedDeliveryDays: '5+',
    },
    {
      type: 'SUNGLASSES',
      expectedDeliveryDays: '1',
    },
  ],
})
